angular.module('sq.help', [
	'sq.help.component'
])
.config(($routeProvider) => {
  $routeProvider.when( '/help/:section?', {
    template: "<sq-help section='$resolve.section'></sq-help>",
    title: 'Guide',
    reloadOnSearch: false,
    secureAsync: false,
    resolve: {
      section: ($route) => $route.current.params.section
    },
  });
});